import { PanelProps } from '@grafana/data';
import React from 'react';
import { SimpleOptions } from 'types';

interface Props extends PanelProps<SimpleOptions> {}

export const SimplePanel: React.FC<Props> = ({ options, data, width, height }) => {

let youtubeUrl = "https://www.youtube.com/embed/live_stream?channel=" + options.channelId+ "&autoplay=1";

  return (
    <iframe width={width} height={height} src={youtubeUrl} frameBorder='0'></iframe>
  );
};

