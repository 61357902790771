import { PanelPlugin } from '@grafana/data';
import { SimpleOptions } from './types';
import { SimplePanel } from './SimplePanel';

export const plugin = new PanelPlugin<SimpleOptions>(SimplePanel).setPanelOptions(builder => {
  return builder
    .addTextInput({
      path: 'channelId',
      name: 'Channel ID',
      description: 'ex: UCtoEtlyUZ9lEgS6B_W2qsYA',
      defaultValue: 'UCtoEtlyUZ9lEgS6B_W2qsYA',
    })

});
